/* eslint-disable no-unused-vars */
window.toslug = function(text){
	return text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}

window.alphaOnly = function(e){
	return /^[a-zA-Z ]$/g.test(String.fromCharCode(e.which));
}

window.fullNameKey = function(e){
	if(/^(8)$/g.test(e.keyCode)) return true;
	return /^[a-zA-Z ]$/g.test(String.fromCharCode(e.which));
}

window.numberKey = function(e){
	return /^[0-9]$/g.test(String.fromCharCode(e.which));
}

window.emailKey = function(e){
	if(/^(8)$/g.test(e.keyCode)) return true;
	return /^[a-zA-Z0-9@+._-]$/g.test(String.fromCharCode(e.which));
}

window.maxWord = function(e, limit){
	if(e.key===" "&&e.target.value.wordLength() == limit) return false
	if(e.key===" "&&e.key===e.target.value.substr(-1)) return false
	return true
}

window.mobileKey = function (e) {
	if (/^(8)$/g.test(e.keyCode)) return true;
	// return /^[0-9+]$/g.test(String.fromCharCode(e.which));
	Gen.interval(() => {
		e.target.value = e.target.value.replace(/[^0-9+]/g, '')
	}, 25, 50)
}

// default validation message
$.extend($.validator.messages, {
	required: function(p,el){
		var element = $(el)
		var placement = $("[error='"+element.attr('name')+"']")
		var label = placement.attr('label') || element.parent().find('input').attr("placeholder") || element.attr('name')
		var msg = ['radio','checkbox'].indexOf(element.attr('type'))>-1?"Select":"Input"
		return msg+" "+label+"."
	},
	email: "Input the valid email.",
	number: "Input the valid number.",
	creditcard: "Please enter a valid credit card number.",
	equalTo: function(p,el){
		var element = $(el)
		var placement = $("[error='"+element.attr('name')+"']")
		var label = placement.attr('label') || element.attr('name')
		return label+" must match."
	},
	maxlength: $.validator.format("Input max {0} characters."),
	minlength: $.validator.format("Input min {0} characters."),
	rangelength: $.validator.format("Input between {0} to {1} characters."),
	range: $.validator.format("Input between {0} to {1}."),
	max: $.validator.format("Input max {0}."),
	min: function(p,el){
		var element = $(el)
		var placement = $("[error='"+element.attr('name')+"']")
		var label = placement.attr('label') || element.attr('name')
		return placement.data('msg-min') || "Input min "+p+"."
	}
});
$.validator.setDefaults({
	ignore: ".novalidate",
	// any other default options and/or rules
});